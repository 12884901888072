/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {
  Container, Segment, Grid, Header, Card, Icon, Divider,
} from 'semantic-ui-react';
import {
  LINK_GOSH_FAIR,
  LINK_ARIDHIA_CONTACT_SD,
  LINK_ARIDHIA_SHINY_APPS,
  LINK_ARIDHIA_LEARNING_CENTRE,
  LINK_ARIDHIA_SUPPORTED_BROWSERS,
  LINK_EMBED_GOSH_VIDEO,
  LINK_ARIDHIA_KB,
  LINK_GOSH_LMS_WS_FUNDAMENTALS,
  LINK_GOSH_LMS_WS_ADVANCED,
  LINK_GOSH_LMS_FAIR,
  LINK_GOSH_HOSP_WORKSPACE_UKSOUTH,
  LINK_GOSH_RESEARCH_WORKSPACE_UKSOUTH,
  ENDPOINT_SECURITY,
  ENDPOINT_REQUEST_WORKSPACE,
} from 'env-create-react-app';

import FAIRLogoGreen from '../images/fair-logo-green.svg';

import workspacesLogo2020Coloured from '../images/workspaces-logo-colour.svg';
import WorkspacesLogo2020White from '../images/workspacesLogo2020-white.svg';
import WSFundamentals from '../images/btn_ws.jpg';
import WSAdvanced from '../images/btn_ws_advanced.jpg';
import FAIRStandard from '../images/btn_fair.jpg';
import poweredByAridhia from '../images/AridhiaDRE-PoweredBy.svg';

function Content() {
  const sendEmail = (e) => {
    const whoTo = e.target.name;
    let mailto = '';
    if (whoTo === 'DRESupport') {
      mailto = 'mailto:DRESupport@gosh.nhs.uk?subject=Fair Data Services';
    } else if (whoTo === 'DREProjects') {
      mailto = 'mailto:DREProjects@gosh.nhs.uk?subject=Request access';
    } else {
      mailto = '';
    }
    window.location.href = mailto;
  };

  return (
    <div className="portal page content">
      <Container>
        <Segment className="portal-elements">

          <Header as="h2">Getting Started</Header>

          <Grid stackable columns="equal">
            <Grid.Column>
              <Card fluid>
                <Card.Header className="content">
                  <Icon name="cog" size="large" className="right floated orange" />
                  <span className="workspace-name">The GOSH Data and Analytics Platform</span>
                </Card.Header>
                <Card.Content className="hero-content">
                  <Card.Description className="hero-child">
                    <p>
                      The Data and Analytics Platform includes FAIR Data Services and Workspaces.
                    </p>
                    <ul>
                      <li>
                        <strong>FAIR</strong>
                        {' '}
                        includes integrated rare disease data from clinical trials, observational
                        studies, real-world data, patient registries and other sources.
                      </li>
                      <li>
                        In
                        {' '}
                        <strong>Workspaces</strong>
                        , advanced analytics capabilities allow efficient and effective
                        interrogation of data to generate insights, analyses and solutions.
                      </li>
                    </ul>
                    <p>
                      When using the GOSH workspaces portal, please refer to the current list of
                      {' '}
                      <a href={LINK_ARIDHIA_SUPPORTED_BROWSERS} target="_blank" rel="noreferrer">supported browsers</a>
                      .
                    </p>
                    <p>
                      <a className="ui purple button gosh" href={LINK_GOSH_LMS_WS_FUNDAMENTALS} target="_blank" rel="noreferrer">
                        <div><img src={WSFundamentals} alt="Workspaces Fundamentals" className="ui image" /></div>
                        <div>Introduction to Workspaces</div>
                      </a>
                      <a className="ui purple button gosh" href={LINK_GOSH_LMS_FAIR} target="_blank" rel="noreferrer">
                        <div><img src={FAIRStandard} alt="FAIR Standard" className="ui image" /></div>
                        <div>FAIR Data Service Learning</div>
                      </a>
                      <a className="ui purple button gosh" href={LINK_GOSH_LMS_WS_ADVANCED} target="_blank" rel="noreferrer">
                        <div><img src={WSAdvanced} alt="Workspaces Advanced" className="ui image" /></div>
                        <div>Workspace Learning</div>
                      </a>
                    </p>
                  </Card.Description>
                  <Card.Description className="hero-child">
                    <iframe title="ICODA Workspace Video" width="100%" height="315" src={LINK_EMBED_GOSH_VIDEO} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
                  </Card.Description>
                </Card.Content>
              </Card>
            </Grid.Column>
          </Grid>

          <Divider />
          <Header as="h2">Quick Start</Header>
          <p id="lead-text">
            <strong>New users</strong>
            : please
            {' '}
            <a href="https://workspaces.uksouth.GOSH.aridhia.io/" target="_blank" rel="noreferrer">sign up here</a>
            {' '}
            before visiting Workspaces and FAIR. Platform access will be reviewed within 24-48 hours
            .
            More info for non-GOSH users
            {' '}
            <a href="https://knowledgebase.aridhia.io/article/registering-for-a-workspaces-account/" target="_blank" rel="noreferrer">here</a>
            .
          </p>
          <p>
            Use these Quick Start steps to explore the platform and available data. If you are
            interested in using data for research or study, please request a research workspace
            at the link below before submitting a Data Access Request in FAIR. A member of the
            GOSH team will address workspace requests within 24-48 business hours.
          </p>
          <Grid stackable columns="equal">
            <Grid.Column>
              <Card fluid>
                <Card.Header className="content">
                  <img src={FAIRLogoGreen} alt="" aria-hidden="true" className="right floated icon" />
                  <a href={LINK_GOSH_FAIR} target="_blank" rel="noreferrer" className="workspace-name">1. Discover Data</a>
                </Card.Header>
                <Card.Content>
                  <div className="description">

                    {' '}
                    <p>

                      Fair Data Services are currently under development at GOSH.
                      Please contact
                      {'   '}
                      <a role="link" onKeyPress={sendEmail} tabIndex={-1} onClick={sendEmail} name="DRESupport">DRE Support</a>
                      {' '}
                      if you would like more information.

                    </p>

                  </div>
                </Card.Content>
              </Card>
            </Grid.Column>
            <Grid.Column>
              <Card fluid>
                <Card.Header className="content">
                  <Icon name="exclamation circle" size="large" className="right floated orange" />
                  <a href={ENDPOINT_REQUEST_WORKSPACE} className="workspace-name">2. Request Access</a>
                </Card.Header>
                <Card.Content>
                  <Card.Description>
                    <p>

                      If you would like to discuss your project requirements
                      and request access to the platform, please contact the
                      {' '}
                      <a role="link" onKeyPress={sendEmail} tabIndex={-1} onClick={sendEmail} name="DREProjects">DRE Projects team.</a>

                    </p>
                  </Card.Description>
                </Card.Content>
              </Card>
            </Grid.Column>
            <Grid.Column>
              <Card fluid>
                <Card.Header className="content">
                  <img src={workspacesLogo2020Coloured} alt="" className="right floated icon" />
                  <a target="_blank" rel="noreferrer" className="workspace-name">3. Conduct Research</a>
                </Card.Header>
                <Card.Content>
                  <Card.Description>
                    <ol>
                      <li>
                        Go to Workspaces
                        <a href={LINK_GOSH_HOSP_WORKSPACE_UKSOUTH} className="ui button" target="_blank" rel="noreferrer">
                          <img alt="Workspaces logo" src={WorkspacesLogo2020White} className="icon icon-inline icon-inline-ws" />
                          Hospital Workspaces
                        </a>
                        <a href={LINK_GOSH_RESEARCH_WORKSPACE_UKSOUTH} className="ui button" target="_blank" rel="noreferrer">
                          <img alt="Workspaces logo" src={WorkspacesLogo2020White} className="icon icon-inline icon-inline-ws" />
                          Research Workspaces
                        </a>
                      </li>
                      <li>
                        From the Workspaces home page, you can view your workspaces and invitations.
                      </li>
                      <li>
                        Accept any pending invites and click to enter your workspace.
                      </li>
                    </ol>
                  </Card.Description>
                </Card.Content>
              </Card>
            </Grid.Column>
          </Grid>
          <Divider />

          <Header as="h2">Useful Information</Header>

          <Grid stackable columns="equal">
            <Grid.Column>
              <Card fluid>
                <Card.Header className="content">
                  <Icon name="shield alternate" size="large" className="right floated orange" />
                  <a className="workspace-name" href={ENDPOINT_SECURITY}>Data security</a>
                </Card.Header>
                <Card.Content>
                  <Card.Description>
                    <p>
                      Data can be highly sensitive: it can be at risk for re-identification even
                      when explicit personally identifiable information is removed. Find out more
                      about the measures that we use to keep data safe.
                    </p>
                  </Card.Description>
                </Card.Content>
              </Card>
            </Grid.Column>
            <Grid.Column>
              <Card fluid>
                <Card.Header className="content">
                  <Icon name="question circle outline" size="large" className="right floated orange" />
                  <a className="workspace-name" target="_blank" rel="noreferrer" href={LINK_ARIDHIA_CONTACT_SD}>Need Help?</a>
                </Card.Header>
                <Card.Content>
                  <Card.Description className="need-help">
                    <div>
                      <a href={LINK_ARIDHIA_CONTACT_SD} target="_blank" rel="noreferrer">
                        <i aria-hidden="true" className="life-ring x-large icon purple" />
                        Service Desk
                      </a>
                    </div>
                    <div>
                      <a href={LINK_ARIDHIA_KB} target="_blank" rel="noreferrer">
                        <i aria-hidden="true" className="question circle outline x-large icon purple" />
                        Knowledge Base
                      </a>
                    </div>
                    <div>
                      <a href={LINK_ARIDHIA_SHINY_APPS} target="_blank" rel="noreferrer">
                        <i aria-hidden="true" className="desktop x-large icon purple" />
                        Shiny Apps
                      </a>
                    </div>
                    <div>
                      <a href={LINK_ARIDHIA_LEARNING_CENTRE} target="_blank" rel="noreferrer">
                        <i aria-hidden="true" className="graduation x-large icon purple" />
                        Learning Center
                      </a>
                    </div>
                  </Card.Description>
                </Card.Content>
              </Card>
            </Grid.Column>
          </Grid>
        </Segment>
        <Segment className="portal-elements">
          <img src={poweredByAridhia} alt="Powered by Aridhia DRE" className="powered-by-aridhia" />
        </Segment>
      </Container>
    </div>

  );
}

export default Content;
