const getDomain = () => {
  const urlParts = window.location.hostname.split('.');

  return urlParts
    .slice(0)
    .slice(-(urlParts.length === 4 ? 3 : 2))
    .join('.');
};

export default getDomain;
