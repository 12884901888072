import { useQuery } from 'react-query';

import { API_BASE, API_ENDPOINT_SERVICE_BANNER } from 'env-create-react-app';

export const useAnnouncements = () => {
  const fetchAnnouncements = () => fetch(`${API_BASE}${API_ENDPOINT_SERVICE_BANNER}`, {
    method: 'GET',
    headers: new Headers({
      Authorization: `Bearer ${window.sessionStorage.getItem('msal.idtoken')}`,
    }),
  }).then((res) => res.json());
  const { data } = useQuery('announcement', fetchAnnouncements);

  return {
    announcements: data && data.data ? data.data.serviceBanners : [],
  };
};

export default useAnnouncements;
