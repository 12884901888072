import React from 'react';

import { Header, Button } from 'semantic-ui-react';

import {
  LINK_GOSH_INFO,
} from 'env-create-react-app';

import DreOverview from '../images/DRE-Overview.jpg';

function toggleOverview() {
  const element = document.getElementById('flex');
  if (element.classList.contains('overview-hidden')) {
    document.getElementById('overview-content').style.marginLeft = '1rem';
  } else {
    const contentWidth = document.getElementById('overview-content').offsetWidth - 56;
    document.getElementById('overview-content').style.marginLeft = `-${contentWidth}px`;
  }
  element.classList.toggle('overview-hidden');
}

const Overview = () => (
  <div className="overview">
    <Button type="button" aria-label="Toggle Overview" className="overview_toggle toggle left" onClick={toggleOverview}><i className="arrow circle left icon" /></Button>
    <Button type="button" aria-label="Toggle Overview" className="overview_toggle toggle right" onClick={toggleOverview}><i className="arrow circle right icon" /></Button>
    <div className="content" id="overview-content">
      <Header as="h1">Overview</Header>
      <Header as="h2">
        You can find more information about GOSH
        {' '}
        <a href={LINK_GOSH_INFO} target="_blank" rel="noreferrer">here</a>
        .
      </Header>
      <div className="ui image-container">
        <img src={DreOverview} alt="Workspaces Overview" className="ui image" />
      </div>

      <p>
        The research lifecycle helps to illustrate the ways in which the Aridhia DRE
        supports the entire end-to-end research process, and how this cyclical nature
        enriches further study.
      </p>
      <p>
        FAIR Data Services allows for the cataloguing and description of available data,
        as well as the capability to request access and query it. That data is then analysed
        in Workspaces where scripts, models, visualisations, and reports are developed. The
        derived data from this work can be fed back into the research lifecycle, to be discovered
        by future teams to be built upon.
      </p>
      <p>
        All of this is underpinned by a comprehensive audit to ensure reproducibility, secure data
        management, reliable and scalable infrastructure, and world-class analytics.
      </p>

    </div>
  </div>
);

export default Overview;
