import React from 'react';
import PropTypes from 'prop-types';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useLocation,
} from 'react-router-dom';

import {
  ENDPOINT_SECURITY,
  ENDPOINT_REQUEST_WORKSPACE,
} from 'env-create-react-app';

import Header from './components/Header';
import Announcements from './components/Announcements/Announcements';
import Overview from './components/Overview';
import Content from './components/Content';
import Security from './components/Security';
import RequestWorkspace from './components/RequestWorkspace';

import {
  msalObj,
  signIn,
  signOut,
  handleRedirect,
} from './auth/signin';

class App extends React.Component {
  constructor(props) {
    super(props);

    // this.pathname = useLocation();

    // Register Callbacks for Redirect flow
    handleRedirect(); // handles any redirect (like request password change)

    // eslint-disable-next-line no-restricted-globals
    history.scrollRestoration = 'manual'; // prevent autoscroll on page reload. Can sometimes prevent banners from showing.

    let loggedIn = false;
    let name = '';
    if (msalObj.getAccount()) {
      loggedIn = true;
      const account = msalObj.getAccount();

      if (typeof account.idToken === 'undefined' || account.idToken == null) {
        console.error('Failed to obtain idToken');
        console.error(account);
      } else {
        const givenName = account.idToken.given_name;
        const familyName = account.idToken.family_name;
        name = `${givenName} ${familyName}`;
      }
    }

    this.state = { loggedIn, name };
  }

  appSignOut() {
    signOut();
    this.setState({ loggedIn: false });
  }

  render() {
    const { loggedIn, name } = this.state;

    return (
      <>
        <Header loggedIn={loggedIn} name={name} signIn={signIn} signOut={() => this.appSignOut()} />
        <div id="announcement">
          <Announcements />
        </div>
        <div id="flex">
          <Router>
            <AppContentRouter loggedIn={loggedIn} />
          </Router>
        </div>
      </>
    );
  }
}

const AppContentRouter = ({ loggedIn }) => {
  const { pathname } = useLocation();

  return (
    <Switch>
      <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
      <Route exact path={ENDPOINT_SECURITY}>
        <Security />
      </Route>
      <Route exact path={ENDPOINT_REQUEST_WORKSPACE}>
        <RequestWorkspace loggedIn={loggedIn} signIn={signIn} />
      </Route>
      <Route exact path="/">
        <Overview />
        <Content />
      </Route>
      <Redirect to="/" />
    </Switch>
  );
};

AppContentRouter.propTypes = {
  loggedIn: PropTypes.bool.isRequired,
};

export default App;
