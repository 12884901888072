import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

const allowedElements = [
  'text',
  'break',
  'paragraph',
  'emphasis',
  'strong',
  'thematicBreak',
  'blockquote',
  'link',
  'linkReference',
  'list',
  'listItem',
  'heading',
];

// eslint-disable-next-line react/prop-types
const MarkdownRender = ({ value }) => (
  <ReactMarkdown
    linkTarget="_blank"
    skipHtml
    allowedElements={allowedElements}
    remarkPlugins={[remarkGfm]}
  >
    {value}
  </ReactMarkdown>
);

export default MarkdownRender;
